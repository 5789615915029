import React from 'react';

import { svgSpriteSheetFetchPath } from '../generated/svg-sprite-sheet-stuff';

let cache = '';

export function SvgSpriteSheetLoader() {
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    // note: this is not a normal pattern, so be mindful of the implications.
    // The cache will be maintained for as long as the _module_ is available in memory,
    // it will not be cleared if/when the component is unmounted.
    // We are using this cache pattern specifically for Storybook because the loader
    // component is applied as a global decorator, which means whenever a story is rendered,
    // this loader component will be mounted. Without a caching mechanism, this would
    // mean the sprite sheet is fetched for every story rendered on screen.
    // Also worth noting that this loader component is used in the app root (index.tsx) as
    // well, but the cache behavior does not have any adverse impacts because the app root
    // is only mounted once on page load.
    if (!cache) {
      fetch(`${svgSpriteSheetFetchPath}`)
        .then((response) => response.text())
        .then((svg) => {
          cache = svg;
          containerRef.current.innerHTML = cache;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Failed to fetch SVG sprite sheet:', error);
        });
    } else {
      containerRef.current.innerHTML = cache;
    }
  }, []);

  return <div id="svg-spritesheet__ud" style={{ width: 0, height: 0 }} ref={containerRef} />;
}
